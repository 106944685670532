<template>
  <div class="info_box">
    <div class="info_top" v-if="information.solutionFiles && information.solutionFiles.length > 0">
      <div class="top_left">
        <img src="../../../../assets/image/Group 1142814918 (1).png" />
        <span>
          解决方案（{{ information.solutionFiles ? information.solutionFiles.length : 0 }}）
        </span>
      </div>
    </div>
    <div class="info_content" v-if="information.solutionFiles && information.solutionFiles.length > 0">
      <el-descriptions class="margin-top" :column="3" border>
        <el-descriptions-item labelStyle="width: 200px" contentStyle="width: 200px">
          <template slot="label">递交方案</template>
          {{ information.submitSolution ? '是' : '否' }}
        </el-descriptions-item>
        <el-descriptions-item labelStyle="width: 200px" contentStyle="width: 200px" >
          <template slot="label">方案评审</template>
          {{ information.reviewSolution ? '是' : '否' }}
        </el-descriptions-item>
        <el-descriptions-item >
          <template slot="label" >附件上传</template>
          <div class="item_list">
            <div class="file_list" v-for="(item, index) in information.solutions" :key="index">
              <div class="file_name">
                <span>{{ item.name }}</span>
              </div>
              <div style="margin-left: 20px;">
                <el-button type="text" icon="el-icon-download" @click="downloadClick(item)">点击下载</el-button>
              </div>
            </div>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <el-empty :image="require('@/assets/image/Group (10).png')" description="暂无内容" :image-size="240" v-else></el-empty>
  </div>
</template>
<script>
import { downloadFile } from '@/api/visiting'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    information: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    //这里存放数据
    return {}
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 下载文件
  // 下载文件
  async downloadClick(item) {
      let fileName = ' '
     
      fileName = item.name
      const res = await downloadFile({ urlStr: item.url })
      if (res) {
        let blob = new Blob([res])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `${nowtime}${fileName}` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
      } else {
        this.$message.warning('下载失败!')
      }
    }
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {}
}
</script>
<style scoped lang="less">
::v-deep .el-descriptions-item__cell {
  font-size: 16px;
}
::v-deep .el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
  color: #333333;
  padding: 20px;
  text-align: center;
  
}
::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: normal;
}
::v-deep .el-descriptions-item__label {
  width: 10%;
  text-align: center !important;
  font-weight: 550 !important;
}
.info_box {
  width: 100%;
  height: 100%;
}
.info_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  .top_left {
    display: flex;
    font-size: 18px;
    color: #4e93fb;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
}
.search_right {
  width: 460px;
  /deep/ .el-button {
    border: 1px solid #448aff;
    color: #fff;
    border-radius: 0;
    background-color: #448aff;
  }
}
.item_list {
  display: flex;
  flex-direction: column;
  color: #4e93fb;

  .file_list {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    margin-right: 20px;
    .file_name {
      span {
        border-bottom: 1px solid #4e93fb;
      }
    }
    ::v-deep .el-button {
      font-size: 16px;
    }
    .el-icon-download {
      font-size: 20px;
    }
  }
}
.demand {
  margin-bottom: 10px;
}
::v-deep .el-empty__description p {
    margin: 0;
    font-size: 18px;
    color: #909399;
}
</style>
